<template>
    <client-page contentsColClass="" contentsClass="mw-1130px">
        <page-section class="page-section--first pb-0">
            <div class="tit-wrap tit-wrap--sm">
                <h4 class="tit">새로운 소식</h4>
            </div>

            <router-view name="board" code="community" category="news" skin="about-news" :limit="8" />
        </page-section>

        <page-section class="pb-0">
            <template #tit>회사소개</template>

            <v-row class="row--xl">
                <v-col cols="12" md="7">
                    <v-card img="/images/sub/about/about-img.jpg" class="background-position">
                        <v-responsive :aspect-ratio="700 / 400" content-class="d-flex align-center">
                            <div class="pa-20px pa-md-40px">
                                <h3 class="tit tit--xs line-height-135 white--text">
                                    공간에 가치를 더합니다.<br />
                                    언제나 새로운 제품을 선보입니다.<br />
                                    더 편하고 쉬운 서비스를 고민합니다.<br />
                                    항상 고객의 입장을 우선시 합니다.<br />
                                    국내 최초 전담팀 서비스를 운영합니다.
                                </h3>
                            </div>
                        </v-responsive>
                    </v-card>
                </v-col>
                <v-col cols="12" md="">
                    <about-community-article />
                </v-col>
            </v-row>
        </page-section>

        <page-section class="pb-0">
            <template #tit>Since 2001.</template>
            <div class="tit-wrap">
                <h4 class="tit tit--xs">비전, 담대한 시작.</h4>
            </div>
            <v-card>
                <v-img :aspect-ratio="1330 / 400" src="/images/sub/about/about-img2.jpg" />
            </v-card>
            <div class="mt-20px mt-md-40px">
                <p class="txt txt--sm txt--dark">조이포라이프는 2001년부터 삶의 질을 보다 풍부하게 만들어 줄 욕실을 만들기 위해많은 연구와 개발을 해왔습니다. 조이포라이프는 스테인리스 스틸을 기반으로 한 높은 품질의 매립 샤워기와 디지털 샤워 시스템으로 전 세계 욕실 시장에서 가장 진보적인 제품을 만드는 회사로 손꼽혀 왔습니다. 조이포라이프의 디자인하우스와 생산 시스템은 사용자를 깊이 이해하고 그에 적합한 제품을 개발하기 위한 노력을 기울이는 동시에 최고급의 품질을 유지하기 위한 시스템을 구축하고 있습니다. 창사 이래 우리의 가장 큰 목표는 기능과 디자인 모두를 충족하는 솔루션을 제공하는 회사로 발돋움 하는 것이었고 이제 그 영역을 넓혀 거주 공간에 대한 관심, 특히 욕실과 주방에 대한 새로운 문화를 만들고 그 공간에 대한 새로운 해석을 고객분들께 제공하고자 합니다. 이에 조이포라이프는 욕실 및 주방과 관련된 모든 것을 제공하는 최고의 커머스 플랫폼으로 진화를 꿈꾸고 있습니다. 23년의 탄탄한 제조 경험과 대규모 프로젝트 납품을 통해 고객으로부터 받아 온 신뢰, 그리고 우리의 열정을 토대로 대한민국 욕실/주방의 새로운 미래를 열어 가겠습니다.</p>
            </div>
        </page-section>

        <page-section class="pb-0">
            <template #tit>우리는 누구인가</template>
            <div class="tit-wrap tit-wrap--sm">
                <h4 class="tit tit--xs">비지니스, 디자인 및 혁신의 대담한 여정.</h4>
            </div>
            <p class="txt txt--sm txt--dark">조이포라이프는 2001년 설립 이래 23년 동안 샤워 전문 제조 경험과 전문성을 바탕으로 수입 욕실/주방 최고의 솔루션을 제공하는 욕실/주방 전문 기업입니다. 조이포라이프는 자사 제품 개발 및 제조, 해외수출을 통해 그 기술력을 입증해 왔으며 이를 토대로 국내 다양한 대형 재건축, 재개발 프로젝트 납품을 수행해 왔습니다. 또한 국내 최초 콜러 공식 시그니처 쇼룸 운영과 국내 최다 수입브랜드 취급 전문몰을 자체 운영함으로써 국내 프리미엄 수입 욕실/주방 분야에서 여러분의 최고 파트너가 되겠습니다.</p>

            <div class="mt-20px mt-md-40px">
                <v-img :aspect-ratio="1130 / 124" src="/images/logo-outlined.png" />
            </div>

            <div class="mt-20px mt-md-40px">
                <v-row>
                    <v-col cols="6" lg="3">
                        <v-card img="/images/sub/about/about-img3.jpg" class="img-card overflow-hidden h-100 mx-auto">
                            <v-responsive :aspect-ratio="$vuetify.breakpoint.lgAndUp ? 316 / 600 : 1 / 1" content-class="d-flex pt-16px pt-md-40px">
                                <div class="img-card__box pa-16px pa-md-20px">
                                    <div class="txt txt--sm white--text font-weight-bold">HEAD OFFICE</div>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-col>
                    <v-col cols="6" lg="3">
                        <v-card img="/images/sub/about/about-img4.jpg" class="img-card overflow-hidden h-100 mx-auto">
                            <v-responsive :aspect-ratio="$vuetify.breakpoint.lgAndUp ? 316 / 600 : 1 / 1" content-class="d-flex pt-16px pt-md-40px">
                                <div class="img-card__box pa-16px pa-md-20px">
                                    <div class="txt txt--sm white--text font-weight-bold">FACTORY</div>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-col>
                    <v-col cols="6" lg="3">
                        <v-card img="/images/sub/about/about-img5.jpg" class="img-card overflow-hidden h-100 mx-auto">
                            <v-responsive :aspect-ratio="$vuetify.breakpoint.lgAndUp ? 316 / 600 : 1 / 1" content-class="d-flex pt-16px pt-md-40px">
                                <div class="img-card__box pa-16px pa-md-20px">
                                    <div class="txt txt--sm white--text font-weight-bold">SHOWROOM</div>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-col>
                    <v-col cols="6" lg="3">
                        <v-card img="/images/sub/about/about-img6.jpg" class="img-card overflow-hidden h-100 mx-auto">
                            <v-responsive :aspect-ratio="$vuetify.breakpoint.lgAndUp ? 316 / 600 : 1 / 1" content-class="d-flex pt-16px pt-md-40px">
                                <div class="img-card__box pa-16px pa-md-20px">
                                    <div class="txt txt--sm white--text font-weight-bold">PRODUCTS</div>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-col>
                </v-row>
            </div>
        </page-section>

        <page-section class="pb-0">
            <template #tit>우리의 브랜드&사업</template>
            <div class="tit-wrap tit-wrap--sm">
                <h4 class="tit tit--xs">더 나은 내일에 대한 설계</h4>
            </div>
            <p class="txt txt--sm txt--dark">당신의 삶을 보다 풍요롭게. 당신의 디자인 요구를 충족시키는 욕실 및 주방 브랜드를 소개하고 아직 경험하지 못한 우아한 솔루션을 제공하여 새로운 욕실 및 주방 문화를 창조하기 위한 우리의 노력이 곧 우리의 사업영역이고 기회입니다. 더 나아가 우리는 우리의 거주 공간에 대한 아이디어를 바탕으로 새로운 솔루션에 대해 고민하고 있습니다. 의미가 만들어지고, 문제점을 개선하고, 연결을 활성화 시키며 기회가 존재하는 공간에서 우리 삶의 방식에 대한 실용성과 열망에 대해 진지하게 접근하고 있습니다. 라이프스타일 브랜드를 추구하는 기업으로서 우리가 보는 방식, 디자인, 그리고 항상 최선을 다할 수 있다는 근본적인 믿음이 성공의 열쇠를 쥐고 있습니다.</p>

            <div class="mt-20px mt-md-40px">
                <v-row class="row--sm">
                    <v-col cols="6" md="4" lg="2">
                        <v-card color="grey lighten-5" height="100%">
                            <v-responsive :aspect-ratio="1 / 1" content-class="d-flex flex-column justify-space-between">
                                <div class="pa-16px pa-md-20px">
                                    <div class="txt txt--sm font-weight-bold">1</div>
                                </div>
                                <div>
                                    <v-img :aspect-ratio="210 / 88" src="/images/sub/about/about-logo.png" class="mx-auto" />
                                </div>
                                <div class="pa-16px pa-md-20px text-center">
                                    <div class="txt txt--sm">China/USA</div>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-col>
                    <v-col cols="6" md="4" lg="2">
                        <v-card color="grey lighten-5" height="100%">
                            <v-responsive :aspect-ratio="1 / 1" content-class="d-flex flex-column justify-space-between">
                                <div class="pa-16px pa-md-20px">
                                    <div class="txt txt--sm font-weight-bold">2</div>
                                </div>
                                <div>
                                    <v-img :aspect-ratio="210 / 88" src="/images/sub/about/about-logo2.png" class="mx-auto" />
                                </div>
                                <div class="pa-16px pa-md-20px text-center">
                                    <div class="txt txt--sm">Germany</div>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-col>
                    <v-col cols="6" md="4" lg="2">
                        <v-card color="grey lighten-5" height="100%">
                            <v-responsive :aspect-ratio="1 / 1" content-class="d-flex flex-column justify-space-between">
                                <div class="pa-16px pa-md-20px">
                                    <div class="txt txt--sm font-weight-bold">3</div>
                                </div>
                                <div>
                                    <v-img :aspect-ratio="210 / 88" src="/images/sub/about/about-logo3.png" class="mx-auto" />
                                </div>
                                <div class="pa-16px pa-md-20px text-center">
                                    <div class="txt txt--sm">Italy/Turkey</div>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-col>
                    <v-col cols="6" md="4" lg="2">
                        <v-card color="grey lighten-5" height="100%">
                            <v-responsive :aspect-ratio="1 / 1" content-class="d-flex flex-column justify-space-between">
                                <div class="pa-16px pa-md-20px">
                                    <div class="txt txt--sm font-weight-bold">4</div>
                                </div>
                                <div>
                                    <v-img :aspect-ratio="210 / 88" src="/images/sub/about/about-logo4.png" class="mx-auto" />
                                </div>
                                <div class="pa-16px pa-md-20px text-center">
                                    <div class="txt txt--sm">India</div>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-col>
                    <v-col cols="6" md="4" lg="2">
                        <v-card color="grey lighten-5" height="100%">
                            <v-responsive :aspect-ratio="1 / 1" content-class="d-flex flex-column justify-space-between">
                                <div class="pa-16px pa-md-20px">
                                    <div class="txt txt--sm font-weight-bold">5</div>
                                </div>
                                <div>
                                    <v-img :aspect-ratio="210 / 88" src="/images/sub/about/about-logo5.png" class="mx-auto" />
                                </div>
                                <div class="pa-16px pa-md-20px text-center">
                                    <div class="txt txt--sm">China</div>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-col>
                    <v-col cols="6" md="4" lg="2">
                        <v-card color="grey lighten-5" height="100%">
                            <v-responsive :aspect-ratio="1 / 1" content-class="d-flex flex-column justify-space-between">
                                <div class="pa-16px pa-md-20px">
                                    <div class="txt txt--sm font-weight-bold">6</div>
                                </div>
                                <div>
                                    <v-img :aspect-ratio="210 / 88" src="/images/sub/about/about-logo6.png" class="mx-auto" />
                                </div>
                                <div class="pa-16px pa-md-20px text-center">
                                    <div class="txt txt--sm">Italy</div>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-col>
                    <v-col cols="6" md="4" lg="2">
                        <v-card color="grey lighten-5" height="100%">
                            <v-responsive :aspect-ratio="1 / 1" content-class="d-flex flex-column justify-space-between">
                                <div class="pa-16px pa-md-20px">
                                    <div class="txt txt--sm font-weight-bold">7</div>
                                </div>
                                <div>
                                    <v-img :aspect-ratio="210 / 88" src="/images/sub/about/about-logo7.png" class="mx-auto" />
                                </div>
                                <div class="pa-16px pa-md-20px text-center">
                                    <div class="txt txt--sm">China</div>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-col>
                    <v-col cols="6" md="4" lg="2">
                        <v-card color="grey lighten-5" height="100%">
                            <v-responsive :aspect-ratio="1 / 1" content-class="d-flex flex-column justify-space-between">
                                <div class="pa-16px pa-md-20px">
                                    <div class="txt txt--sm font-weight-bold">8</div>
                                </div>
                                <div>
                                    <v-img :aspect-ratio="210 / 88" src="/images/sub/about/about-logo8.png" class="mx-auto" />
                                </div>
                                <div class="pa-16px pa-md-20px text-center">
                                    <div class="txt txt--sm">Sweden</div>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-col>
                    <v-col cols="6" md="4" lg="2">
                        <v-card color="grey lighten-5" height="100%">
                            <v-responsive :aspect-ratio="1 / 1" content-class="d-flex flex-column justify-space-between">
                                <div class="pa-16px pa-md-20px">
                                    <div class="txt txt--sm font-weight-bold">9</div>
                                </div>
                                <div>
                                    <v-img :aspect-ratio="210 / 88" src="/images/sub/about/about-logo9.png" class="mx-auto" />
                                </div>
                                <div class="pa-16px pa-md-20px text-center">
                                    <div class="txt txt--sm">France</div>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-col>
                    <v-col cols="6" md="4" lg="2">
                        <v-card color="grey lighten-5" height="100%">
                            <v-responsive :aspect-ratio="1 / 1" content-class="d-flex flex-column justify-space-between">
                                <div class="pa-16px pa-md-20px">
                                    <div class="txt txt--sm font-weight-bold">10</div>
                                </div>
                                <div>
                                    <v-img :aspect-ratio="210 / 88" src="/images/sub/about/about-logo10.png" class="mx-auto" />
                                </div>
                                <div class="pa-16px pa-md-20px text-center">
                                    <div class="txt txt--sm">Italy</div>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-col>
                    <v-col cols="6" md="4" lg="2">
                        <v-card color="grey lighten-5" height="100%">
                            <v-responsive :aspect-ratio="1 / 1" content-class="d-flex flex-column justify-space-between">
                                <div class="pa-16px pa-md-20px">
                                    <div class="txt txt--sm font-weight-bold">11</div>
                                </div>
                                <div>
                                    <v-img :aspect-ratio="210 / 88" src="/images/sub/about/about-logo11.png" class="mx-auto" />
                                </div>
                                <div class="pa-16px pa-md-20px text-center">
                                    <div class="txt txt--sm">Germany</div>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-col>
                    <v-col cols="6" md="4" lg="2">
                        <v-card color="grey lighten-5" height="100%">
                            <v-responsive :aspect-ratio="1 / 1" content-class="d-flex flex-column justify-space-between">
                                <div class="pa-16px pa-md-20px">
                                    <div class="txt txt--sm font-weight-bold">12</div>
                                </div>
                                <div>
                                    <v-img :aspect-ratio="210 / 88" src="/images/sub/about/about-logo12.png" class="mx-auto" />
                                </div>
                                <div class="pa-16px pa-md-20px text-center">
                                    <div class="txt txt--sm">Italy</div>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-col>
                    <v-col cols="6" md="4" lg="2">
                        <v-card color="grey lighten-5" height="100%">
                            <v-responsive :aspect-ratio="1 / 1" content-class="d-flex flex-column justify-space-between">
                                <div class="pa-16px pa-md-20px">
                                    <div class="txt txt--sm font-weight-bold">13</div>
                                </div>
                                <div>
                                    <v-img :aspect-ratio="210 / 88" src="/images/sub/about/about-logo13.png" class="mx-auto" />
                                </div>
                                <div class="pa-16px pa-md-20px text-center">
                                    <div class="txt txt--sm">Germany</div>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-col>
                    <v-col cols="6" md="4" lg="2">
                        <v-card color="grey lighten-5" height="100%">
                            <v-responsive :aspect-ratio="1 / 1" content-class="d-flex flex-column justify-space-between">
                                <div class="pa-16px pa-md-20px">
                                    <div class="txt txt--sm font-weight-bold">14</div>
                                </div>
                                <div>
                                    <v-img :aspect-ratio="210 / 88" src="/images/sub/about/about-logo14.png" class="mx-auto" />
                                </div>
                                <div class="pa-16px pa-md-20px text-center">
                                    <div class="txt txt--sm">Germany</div>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-col>
                    <v-col cols="6" md="4" lg="2">
                        <v-card color="grey lighten-5" height="100%">
                            <v-responsive :aspect-ratio="1 / 1" content-class="d-flex flex-column justify-space-between">
                                <div class="pa-16px pa-md-20px">
                                    <div class="txt txt--sm font-weight-bold">15</div>
                                </div>
                                <div>
                                    <v-img :aspect-ratio="210 / 88" src="/images/sub/about/about-logo15.png" class="mx-auto" />
                                </div>
                                <div class="pa-16px pa-md-20px text-center">
                                    <div class="txt txt--sm">Germany</div>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-col>
                    <v-col cols="6" md="4" lg="2">
                        <v-card color="grey lighten-5" height="100%">
                            <v-responsive :aspect-ratio="1 / 1" content-class="d-flex flex-column justify-space-between">
                                <div class="pa-16px pa-md-20px">
                                    <div class="txt txt--sm font-weight-bold">16</div>
                                </div>
                                <div>
                                    <v-img :aspect-ratio="210 / 88" src="/images/sub/about/about-logo16.png" class="mx-auto" />
                                </div>
                                <div class="pa-16px pa-md-20px text-center">
                                    <div class="txt txt--sm">China</div>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-col>
                    <v-col cols="6" md="4" lg="2">
                        <v-card color="grey lighten-5" height="100%">
                            <v-responsive :aspect-ratio="1 / 1" content-class="d-flex flex-column justify-space-between">
                                <div class="pa-16px pa-md-20px">
                                    <div class="txt txt--sm font-weight-bold">17</div>
                                </div>
                                <div>
                                    <v-img :aspect-ratio="210 / 88" src="/images/sub/about/about-logo17.png" class="mx-auto" />
                                </div>
                                <div class="pa-16px pa-md-20px text-center">
                                    <div class="txt txt--sm">Germany</div>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-col>
                    <v-col cols="6" md="4" lg="2">
                        <v-card color="grey lighten-5" height="100%">
                            <v-responsive :aspect-ratio="1 / 1" content-class="d-flex flex-column justify-space-between">
                                <div class="pa-16px pa-md-20px">
                                    <div class="txt txt--sm font-weight-bold">18</div>
                                </div>
                                <div>
                                    <v-img :aspect-ratio="210 / 88" src="/images/sub/about/about-logo18.png" class="mx-auto" />
                                </div>
                                <div class="pa-16px pa-md-20px text-center">
                                    <div class="txt txt--sm">Germany</div>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-col>
                    <v-col cols="6" md="4" lg="2">
                        <v-card color="grey lighten-5" height="100%">
                            <v-responsive :aspect-ratio="1 / 1" content-class="d-flex flex-column justify-space-between">
                                <div class="pa-16px pa-md-20px">
                                    <div class="txt txt--sm font-weight-bold">19</div>
                                </div>
                                <div>
                                    <v-img :aspect-ratio="210 / 88" src="/images/sub/about/about-logo19.png" class="mx-auto" />
                                </div>
                                <div class="pa-16px pa-md-20px text-center">
                                    <div class="txt txt--sm">China</div>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-col>
                    <!-- <v-col cols="6" md="4" lg="2">
                        <v-card color="grey lighten-5" height="100%">
                            <v-responsive :aspect-ratio="1 / 1" content-class="d-flex flex-column justify-space-between">
                                <div class="pa-16px pa-md-20px">
                                    <div class="txt txt--sm font-weight-bold">20</div>
                                </div>
                                <div>
                                    <v-img :aspect-ratio="210 / 88" src="/images/sub/about/about-logo20.png" class="mx-auto" />
                                </div>
                                <div class="pa-16px pa-md-20px text-center">
                                    <div class="txt txt--sm">USA</div>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-col>
                    <v-col cols="6" md="4" lg="2">
                        <v-card color="grey lighten-5" height="100%">
                            <v-responsive :aspect-ratio="1 / 1" content-class="d-flex flex-column justify-space-between">
                                <div class="pa-16px pa-md-20px">
                                    <div class="txt txt--sm font-weight-bold">21</div>
                                </div>
                                <div>
                                    <v-img :aspect-ratio="210 / 88" src="/images/sub/about/about-logo21.png" class="mx-auto" />
                                </div>
                                <div class="pa-16px pa-md-20px text-center">
                                    <div class="txt txt--sm">China</div>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-col>
                    <v-col cols="6" md="4" lg="2">
                        <v-card color="grey lighten-5" height="100%">
                            <v-responsive :aspect-ratio="1 / 1" content-class="d-flex flex-column justify-space-between">
                                <div class="pa-16px pa-md-20px">
                                    <div class="txt txt--sm font-weight-bold">22</div>
                                </div>
                                <div>
                                    <v-img :aspect-ratio="210 / 88" src="/images/sub/about/about-logo22.png" class="mx-auto" />
                                </div>
                                <div class="pa-16px pa-md-20px text-center">
                                    <div class="txt txt--sm">Italy</div>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-col> -->
                    <v-col cols="6" md="4" lg="2">
                        <v-card color="grey lighten-5" height="100%">
                            <v-responsive :aspect-ratio="1 / 1" content-class="d-flex flex-column justify-space-between">
                                <div class="pa-16px pa-md-20px">
                                    <div class="txt txt--sm font-weight-bold">20</div>
                                </div>
                                <div>
                                    <v-img :aspect-ratio="210 / 88" src="/images/sub/about/about-logo23.png" class="mx-auto" />
                                </div>
                                <div class="pa-16px pa-md-20px text-center">
                                    <div class="txt txt--sm">China</div>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-col>
                    <!-- <v-col cols="6" md="4" lg="2">
                        <v-card color="grey lighten-5" height="100%">
                            <v-responsive :aspect-ratio="1 / 1" content-class="d-flex flex-column justify-space-between">
                                <div class="pa-16px pa-md-20px">
                                    <div class="txt txt--sm font-weight-bold">24</div>
                                </div>
                                <div>
                                    <v-img :aspect-ratio="210 / 88" src="/images/sub/about/about-logo24.png" class="mx-auto" />
                                </div>
                                <div class="pa-16px pa-md-20px text-center">
                                    <div class="txt txt--sm">France</div>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-col> -->
                    <v-col cols="6" md="4" lg="2">
                        <v-card color="grey lighten-5" height="100%">
                            <v-responsive :aspect-ratio="1 / 1" content-class="d-flex flex-column justify-space-between">
                                <div class="pa-16px pa-md-20px">
                                    <div class="txt txt--sm font-weight-bold">21</div>
                                </div>
                                <div>
                                    <v-img :aspect-ratio="210 / 88" src="/images/sub/about/about-logo25.png" class="mx-auto" />
                                </div>
                                <div class="pa-16px pa-md-20px text-center">
                                    <div class="txt txt--sm">Germany</div>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-col>
                </v-row>
            </div>
        </page-section>

        <page-section class="pb-0">
            <template #tit>유산</template>
            <div class="tit-wrap tit-wrap--sm">
                <h4 class="tit tit--xs">2001창립, 과거와 현재 그리고 미래</h4>
            </div>
            <p class="txt txt--sm txt--dark">끊임없는 도전정신. 2001년 이후 23년 동안 국내 주거환경 문화를 바꾸기 위해 꿋꿋하게 걸어왔습니다. 그동안 걸어온 이 길이 바로 우리의 출발점이자 앞으로 나가야 할 목표입니다. 고객 지향형 서비스를 지향하며 새로운 주거 공간 문화를 창조하는 조이포라이프의 성장은 바로 고객 지향형 브랜드에 기반합니다.</p>

            <div class="mt-20px mt-md-40px">
                <v-row>
                    <v-col cols="6" lg="3">
                        <v-card color="grey lighten-5" height="100%">
                            <v-responsive :aspect-ratio="1 / 1">
                                <div class="pa-16px pa-md-20px">
                                    <div class="font-size-30 font-size-sm-36 font-size-md-40 font-size-xl-60 font-weight-black text-outlined-dark letter-spacing-n02">2001</div>
                                </div>
                                <div class="pa-16px pa-md-20px">
                                    <v-row class="row--xs">
                                        <v-col cols="12">
                                            <p class="txt txt--sm">주식회사 조이포라이프 설립</p>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-col>
                    <v-col cols="6" lg="3">
                        <v-card color="grey lighten-5" height="100%">
                            <v-responsive :aspect-ratio="1 / 1">
                                <div class="pa-16px pa-md-20px">
                                    <div class="font-size-30 font-size-sm-36 font-size-md-40 font-size-xl-60 font-weight-black text-outlined-dark letter-spacing-n02">2007</div>
                                </div>
                                <div class="pa-16px pa-md-20px">
                                    <v-row class="row--xs">
                                        <v-col cols="12">
                                            <p class="txt txt--sm">ISO 9001 인증</p>
                                        </v-col>
                                        <v-col cols="12">
                                            <p class="txt txt--sm">ISO 14001 인증</p>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-col>
                    <v-col cols="6" lg="3">
                        <v-card color="grey lighten-5" height="100%">
                            <v-responsive :aspect-ratio="1 / 1">
                                <div class="pa-16px pa-md-20px">
                                    <div class="font-size-30 font-size-sm-36 font-size-md-40 font-size-xl-60 font-weight-black text-outlined-dark letter-spacing-n02">2008</div>
                                </div>
                                <div class="pa-16px pa-md-20px">
                                    <v-row class="row--xs">
                                        <v-col cols="12">
                                            <p class="txt txt--sm">GIO 샤워시스템 Good Design 획득</p>
                                        </v-col>
                                        <v-col cols="12">
                                            <p class="txt txt--sm">GIO 샤워시스템 개발</p>
                                        </v-col>
                                        <v-col cols="12">
                                            <p class="txt txt--sm">LOSH 샤워 50종</p>
                                        </v-col>
                                        <v-col cols="12">
                                            <p class="txt txt--sm">기업부설연구소 설립</p>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-col>
                    <v-col cols="6" lg="3">
                        <v-card color="grey lighten-5" height="100%">
                            <v-responsive :aspect-ratio="1 / 1">
                                <div class="pa-16px pa-md-20px">
                                    <div class="font-size-30 font-size-sm-36 font-size-md-40 font-size-xl-60 font-weight-black text-outlined-dark letter-spacing-n02">2009</div>
                                </div>
                                <div class="pa-16px pa-md-20px">
                                    <v-row class="row--xs">
                                        <v-col cols="12">
                                            <p class="txt txt--sm">기업부설연구소 디자인 개발실 인증</p>
                                        </v-col>
                                        <v-col cols="12">
                                            <p class="txt txt--sm">cUPC인증(미국)</p>
                                        </v-col>
                                        <v-col cols="12">
                                            <p class="txt txt--sm">TOTO 수출계약</p>
                                        </v-col>
                                        <v-col cols="12">
                                            <p class="txt txt--sm">미국 애틀란타 KBIS전시회 출품</p>
                                        </v-col>
                                        <!-- <v-col cols="12">
                                            <p class="txt txt--sm">지사설립(프랑크푸르트)</p>
                                        </v-col> -->
                                    </v-row>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-col>
                    <v-col cols="6" lg="3">
                        <v-card color="grey lighten-5" height="100%">
                            <v-responsive :aspect-ratio="1 / 1">
                                <div class="pa-16px pa-md-20px">
                                    <div class="font-size-30 font-size-sm-36 font-size-md-40 font-size-xl-60 font-weight-black text-outlined-dark letter-spacing-n02">2010</div>
                                </div>
                                <div class="pa-16px pa-md-20px">
                                    <v-row class="row--xs">
                                        <v-col cols="12">
                                            <p class="txt txt--sm">Sililor Laufen에 rain shower head 공급사로 지정</p>
                                        </v-col>
                                        <v-col cols="12">
                                            <p class="txt txt--sm">세계 최초 Full touch shower System T-shower 출시</p>
                                        </v-col>
                                        <v-col cols="12">
                                            <p class="txt txt--sm">미국 시카고 KBIS전시회 참가</p>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-col>
                    <v-col cols="6" lg="3">
                        <v-card color="grey lighten-5" height="100%">
                            <v-responsive :aspect-ratio="1 / 1">
                                <div class="pa-16px pa-md-20px">
                                    <div class="font-size-30 font-size-sm-36 font-size-md-40 font-size-xl-60 font-weight-black text-outlined-dark letter-spacing-n02">2011</div>
                                </div>
                                <div class="pa-16px pa-md-20px">
                                    <v-row class="row--xs">
                                        <!-- <v-col cols="12">
                                            <p class="txt txt--sm">ISO9001 인증</p>
                                        </v-col> -->
                                        <v-col cols="12">
                                            <p class="txt txt--sm">상하이 KBC 최초 참가</p>
                                        </v-col>
                                        <v-col cols="12">
                                            <p class="txt txt--sm">독일 ISH 최초 참가</p>
                                        </v-col>
                                        <v-col cols="12">
                                            <p class="txt txt--sm">창립 10주년 기념</p>
                                        </v-col>
                                        <v-col cols="12">
                                            <p class="txt txt--sm">유망 중소기업 인증</p>
                                        </v-col>
                                        <v-col cols="12">
                                            <p class="txt txt--sm">업체별 원산지인증 수출자 인증</p>
                                        </v-col>
                                        <v-col cols="12">
                                            <p class="txt txt--sm">IF 디자인어워드 T-Shower and GIO® 수상</p>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-col>
                    <v-col cols="6" lg="3">
                        <v-card color="grey lighten-5" height="100%">
                            <v-responsive :aspect-ratio="1 / 1">
                                <div class="pa-16px pa-md-20px">
                                    <div class="font-size-30 font-size-sm-36 font-size-md-40 font-size-xl-60 font-weight-black text-outlined-dark letter-spacing-n02">2012</div>
                                </div>
                                <div class="pa-16px pa-md-20px">
                                    <v-row class="row--xs">
                                        <v-col cols="12">
                                            <p class="txt txt--sm">벤쳐기업인증</p>
                                        </v-col>
                                        <v-col cols="12">
                                            <p class="txt txt--sm">도곡동 쇼룸 오픈</p>
                                        </v-col>
                                        <v-col cols="12">
                                            <p class="txt txt--sm">KOHLER 한국 대리점 계약</p>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-col>
                    <v-col cols="6" lg="3">
                        <v-card color="grey lighten-5" height="100%">
                            <v-responsive :aspect-ratio="1 / 1">
                                <div class="pa-16px pa-md-20px">
                                    <div class="font-size-30 font-size-sm-36 font-size-md-40 font-size-xl-60 font-weight-black text-outlined-dark letter-spacing-n02">2013</div>
                                </div>
                                <div class="pa-16px pa-md-20px">
                                    <v-row class="row--xs">
                                        <v-col cols="12">
                                            <p class="txt txt--sm">제주 헬스케어타운 욕실부문 턴키납품(1차)</p>
                                        </v-col>
                                        <v-col cols="12">
                                            <p class="txt txt--sm">3백만불 수출탑 수상</p>
                                        </v-col>
                                        <v-col cols="12">
                                            <p class="txt txt--sm">KC 인증 획득</p>
                                        </v-col>
                                        <!-- <v-col cols="12">
                                            <p class="txt txt--sm">Losh 샤워 50종</p>
                                        </v-col> -->
                                    </v-row>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-col>
                    <v-col cols="6" lg="3">
                        <v-card color="grey lighten-5" height="100%">
                            <v-responsive :aspect-ratio="1 / 1">
                                <div class="pa-16px pa-md-20px">
                                    <div class="font-size-30 font-size-sm-36 font-size-md-40 font-size-xl-60 font-weight-black text-outlined-dark letter-spacing-n02">2014</div>
                                </div>
                                <div class="pa-16px pa-md-20px">
                                    <v-row class="row--xs">
                                        <v-col cols="12">
                                            <p class="txt txt--sm">제주 헬스케어타운 욕실부문 턴키납품(2차)</p>
                                        </v-col>
                                        <v-col cols="12">
                                            <p class="txt txt--sm">제2공장 설립 (자동화라인 1호기 설치)</p>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-col>
                    <v-col cols="6" lg="3">
                        <v-card color="grey lighten-5" height="100%">
                            <v-responsive :aspect-ratio="1 / 1">
                                <div class="pa-16px pa-md-20px">
                                    <div class="font-size-30 font-size-sm-36 font-size-md-40 font-size-xl-60 font-weight-black text-outlined-dark letter-spacing-n02">2015</div>
                                </div>
                                <div class="pa-16px pa-md-20px">
                                    <v-row class="row--xs">
                                        <v-col cols="12">
                                            <p class="txt txt--sm">5T 샤워 디자인, 개발 완료</p>
                                        </v-col>
                                        <v-col cols="12">
                                            <p class="txt txt--sm">화성 사옥, 공장 이전</p>
                                        </v-col>
                                        <v-col cols="12">
                                            <p class="txt txt--sm">중국 KBIS전시회 개최</p>
                                        </v-col>
                                        <v-col cols="12">
                                            <p class="txt txt--sm">인도 JAQUAR 11억 유상증자</p>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-col>
                    <v-col cols="6" lg="3">
                        <v-card color="grey lighten-5" height="100%">
                            <v-responsive :aspect-ratio="1 / 1">
                                <div class="pa-16px pa-md-20px">
                                    <div class="font-size-30 font-size-sm-36 font-size-md-40 font-size-xl-60 font-weight-black text-outlined-dark letter-spacing-n02">2016</div>
                                </div>
                                <div class="pa-16px pa-md-20px">
                                    <v-row class="row--xs">
                                        <v-col cols="12">
                                            <p class="txt txt--sm">뿌리기업 인증 획득</p>
                                        </v-col>
                                        <v-col cols="12">
                                            <p class="txt txt--sm">KIMES(의료기) 전시회 개최</p>
                                        </v-col>
                                        <v-col cols="12">
                                            <p class="txt txt--sm">글로벌 강소기업 인증 취득</p>
                                        </v-col>
                                        <v-col cols="12">
                                            <p class="txt txt--sm">5백만불 수출탑 수상</p>
                                        </v-col>
                                        <v-col cols="12">
                                            <p class="txt txt--sm">제2공장 자동도금화라인 2호기 증설</p>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-col>
                    <v-col cols="6" lg="3">
                        <v-card color="grey lighten-5" height="100%">
                            <v-responsive :aspect-ratio="1 / 1">
                                <div class="pa-16px pa-md-20px">
                                    <div class="font-size-30 font-size-sm-36 font-size-md-40 font-size-xl-60 font-weight-black text-outlined-dark letter-spacing-n02">2017</div>
                                </div>
                                <div class="pa-16px pa-md-20px">
                                    <v-row class="row--xs">
                                        <v-col cols="12">
                                            <p class="txt txt--sm">Kohler Kitchen 런칭</p>
                                        </v-col>
                                        <v-col cols="12">
                                            <p class="txt txt--sm">6.5억 유상증자</p>
                                        </v-col>
                                        <!-- <v-col cols="12">
                                            <p class="txt txt--sm">유망중소기업 인증</p>
                                        </v-col> -->
                                        <v-col cols="12">
                                            <p class="txt txt--sm">글로벌 강소기업 인증</p>
                                        </v-col>
                                        <!-- <v-col cols="12">
                                            <p class="txt txt--sm">미국 애틀란타 KBIS전시회 출품</p>
                                        </v-col> -->
                                        <v-col cols="12">
                                            <p class="txt txt--sm">지사설립(프랑크푸르트)</p>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-col>
                    <v-col cols="6" lg="3">
                        <v-card color="grey lighten-5" height="100%">
                            <v-responsive :aspect-ratio="1 / 1">
                                <div class="pa-16px pa-md-20px">
                                    <div class="font-size-30 font-size-sm-36 font-size-md-40 font-size-xl-60 font-weight-black text-outlined-dark letter-spacing-n02">2018</div>
                                </div>
                                <div class="pa-16px pa-md-20px">
                                    <v-row class="row--xs">
                                        <v-col cols="12">
                                            <p class="txt txt--sm">콜러 주방 가구 런칭</p>
                                        </v-col>
                                        <v-col cols="12">
                                            <p class="txt txt--sm">유망중소기업 인증취득</p>
                                        </v-col>
                                        <v-col cols="12">
                                            <p class="txt txt--sm">부산 LCT LED-SHOWER 납품</p>
                                        </v-col>
                                        <v-col cols="12">
                                            <p class="txt txt--sm">미아 9-1 재건축 아파트 주방가구 및 욕실부분 턴키 납품</p>
                                        </v-col>
                                        <v-col cols="12">
                                            <p class="txt txt--sm">LUMOS LED 샤워헤드 신제품 개발</p>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-col>
                    <v-col cols="6" lg="3">
                        <v-card color="grey lighten-5" height="100%">
                            <v-responsive :aspect-ratio="1 / 1">
                                <div class="pa-16px pa-md-20px">
                                    <div class="font-size-30 font-size-sm-36 font-size-md-40 font-size-xl-60 font-weight-black text-outlined-dark letter-spacing-n02">2019</div>
                                </div>
                                <div class="pa-16px pa-md-20px">
                                    <v-row class="row--xs">
                                        <v-col cols="12">
                                            <p class="txt txt--sm">독일 ISH 최초 참가</p>
                                        </v-col>
                                        <v-col cols="12">
                                            <p class="txt txt--sm">120억 미아 9-1 재건축아파트 주방가구 및 욕실부분 턴키 납품</p>
                                        </v-col>
                                        <v-col cols="12">
                                            <p class="txt txt--sm">90억 제주드림타워 재건축 아파트 욕실부분 턴키 납품</p>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-col>
                    <v-col cols="6" lg="3">
                        <v-card color="grey lighten-5" height="100%">
                            <v-responsive :aspect-ratio="1 / 1">
                                <div class="pa-16px pa-md-20px">
                                    <div class="font-size-30 font-size-sm-36 font-size-md-40 font-size-xl-60 font-weight-black text-outlined-dark letter-spacing-n02">2020</div>
                                </div>
                                <div class="pa-16px pa-md-20px">
                                    <v-row class="row--xs">
                                        <v-col cols="12">
                                            <p class="txt txt--sm">개포4단지 콜러욕실 110억 수주</p>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-col>
                    <v-col cols="6" lg="3">
                        <v-card color="grey lighten-5" height="100%">
                            <v-responsive :aspect-ratio="1 / 1">
                                <div class="pa-16px pa-md-20px">
                                    <div class="font-size-30 font-size-sm-36 font-size-md-40 font-size-xl-60 font-weight-black text-outlined-dark letter-spacing-n02">2021</div>
                                </div>
                                <div class="pa-16px pa-md-20px">
                                    <v-row class="row--xs">
                                        <v-col cols="12">
                                            <p class="txt txt--sm">Aran world의 (Denovo cucine)런칭</p>
                                        </v-col>
                                        <v-col cols="12">
                                            <p class="txt txt--sm">부산 협성마리나 콜러주방 918세대</p>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-col>
                    <v-col cols="6" lg="3">
                        <v-card color="grey lighten-5" height="100%">
                            <v-responsive :aspect-ratio="1 / 1">
                                <div class="pa-16px pa-md-20px">
                                    <div class="font-size-30 font-size-sm-36 font-size-md-40 font-size-xl-60 font-weight-black text-outlined-dark letter-spacing-n02">2022</div>
                                </div>
                                <div class="pa-16px pa-md-20px">
                                    <v-row class="row--xs">
                                        <!-- <v-col cols="12">
                                            <p class="txt txt--sm">개포 4단지 납품</p>
                                        </v-col> -->
                                        <v-col cols="12">
                                            <p class="txt txt--sm">제주 포레팰리스 타운하우스 계약</p>
                                        </v-col>
                                        <v-col cols="12">
                                            <p class="txt txt--sm">광주 생타드레스 Denovo, Newform 설치</p>
                                        </v-col>
                                        <v-col cols="12">
                                            <p class="txt txt--sm">주안 파크자이, 흑석리버 파크자이, 개포프레지던트 자이 욕실용품 납품</p>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-col>
                    <v-col cols="6" lg="3">
                        <v-card color="grey darken-4" height="100%">
                            <v-responsive :aspect-ratio="1 / 1">
                                <div class="pa-16px pa-md-20px">
                                    <div class="font-size-30 font-size-sm-36 font-size-md-40 font-size-xl-60 font-weight-black text-outlined-white letter-spacing-n02">2023</div>
                                </div>
                                <div class="pa-16px pa-md-20px">
                                    <v-row class="row--xs">
                                        <v-col cols="12">
                                            <p class="txt txt--sm white--text">미성크로바 1861세대 계약</p>
                                        </v-col>
                                        <v-col cols="12">
                                            <p class="txt txt--sm white--text">북서울 자이 미아 3555세대 납품</p>
                                        </v-col>
                                        <v-col cols="12">
                                            <p class="txt txt--sm white--text">온라인공식몰 오픈</p>
                                        </v-col>
                                        <v-col cols="12">
                                            <p class="txt txt--sm white--text">엔젤 리프트 체어 런칭</p>
                                        </v-col>
                                        <v-col cols="12">
                                            <p class="txt txt--sm white--text">천만불 수출탑 수상</p>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-col>
                    <v-col cols="6" lg="3">
                        <v-card color="grey lighten-5" height="100%">
                            <v-responsive :aspect-ratio="1 / 1">
                                <div class="pa-16px pa-md-20px">
                                    <div class="font-size-30 font-size-sm-36 font-size-md-40 font-size-xl-60 font-weight-black text-outlined-dark letter-spacing-n02">2024</div>
                                </div>
                                <div class="pa-16px pa-md-20px"></div>
                            </v-responsive>
                        </v-card>
                    </v-col>
                    <v-col cols="6" lg="3">
                        <v-card color="grey lighten-5" height="100%">
                            <v-responsive :aspect-ratio="1 / 1">
                                <div class="pa-16px pa-md-20px">
                                    <div class="font-size-30 font-size-sm-36 font-size-md-40 font-size-xl-60 font-weight-black text-outlined-dark letter-spacing-n02">2025</div>
                                </div>
                                <div class="pa-16px pa-md-20px"></div>
                            </v-responsive>
                        </v-card>
                    </v-col>
                </v-row>
            </div>
        </page-section>

        <page-section class="page-section--last">
            <template #tit>우리의 제품</template>
            <div class="tit-wrap tit-wrap--sm">
                <h4 class="tit tit--xs">도전, 그리고 열정.</h4>
            </div>
            <p class="txt txt--sm txt--dark">더 나은 삶의 실현. 조이포라이프는 고객 감동을 최우선으로 좋은 품질의 제품, 효율적인 공정, 그리고 최고의 원료로 제품을 생산합니다. 다년간의 제조 경험과 수출 경험을 토대로 엄격하고 신뢰할 수 있는 품질의 제품을 생산해 왔고 엄격한 품질 관리는 이미 해외에서 검증받았습니다.</p>

            <div class="mt-20px mt-md-40px">
                <v-row>
                    <v-col cols="6" lg="3">
                        <v-card img="/images/sub/about/about-img7.jpg" height="100%">
                            <v-responsive :aspect-ratio="1 / 1">
                                <div class="pa-16px pa-md-20px">
                                    <div class="font-size-30 font-size-sm-36 font-size-md-40 font-size-xl-60 font-weight-black text-outlined-white letter-spacing-n02">1</div>
                                </div>
                                <div class="pa-16px pa-md-20px">
                                    <v-row class="row--xs">
                                        <v-col cols="12">
                                            <p class="txt txt--sm white--text">샤워헤드</p>
                                        </v-col>
                                        <v-col cols="12">
                                            <p class="txt txt--sm white--text">LUMOS</p>
                                        </v-col>
                                        <v-col cols="12">
                                            <p class="txt txt--sm white--text">LUXURY</p>
                                        </v-col>
                                        <v-col cols="12">
                                            <p class="txt txt--sm white--text">PREMIUM</p>
                                        </v-col>
                                        <v-col cols="12">
                                            <p class="txt txt--sm white--text">LOSH</p>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-col>
                    <v-col cols="6" lg="3">
                        <v-card img="/images/sub/about/about-img8.jpg" height="100%">
                            <v-responsive :aspect-ratio="1 / 1">
                                <div class="pa-16px pa-md-20px">
                                    <div class="font-size-30 font-size-sm-36 font-size-md-40 font-size-xl-60 font-weight-black text-outlined-white letter-spacing-n02">2</div>
                                </div>
                                <div class="pa-16px pa-md-20px">
                                    <v-row class="row--xs">
                                        <v-col cols="12">
                                            <p class="txt txt--sm white--text">TNWJS</p>
                                        </v-col>
                                        <v-col cols="12">
                                            <p class="txt txt--sm white--text">D-TOUCH</p>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-col>
                    <v-col cols="6" lg="3">
                        <v-card img="/images/sub/about/about-img9.jpg" height="100%">
                            <v-responsive :aspect-ratio="1 / 1">
                                <div class="pa-16px pa-md-20px">
                                    <div class="font-size-30 font-size-sm-36 font-size-md-40 font-size-xl-60 font-weight-black text-outlined-white letter-spacing-n02">3</div>
                                </div>
                                <div class="pa-16px pa-md-20px">
                                    <v-row class="row--xs">
                                        <v-col cols="12">
                                            <p class="txt txt--sm white--text">핸드샤워</p>
                                        </v-col>
                                        <v-col cols="12">
                                            <p class="txt txt--sm white--text">슬라이드바</p>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-col>
                    <v-col cols="6" lg="3">
                        <v-card img="/images/sub/about/about-img10.jpg" height="100%">
                            <v-responsive :aspect-ratio="1 / 1">
                                <div class="pa-16px pa-md-20px">
                                    <div class="font-size-30 font-size-sm-36 font-size-md-40 font-size-xl-60 font-weight-black text-outlined-white letter-spacing-n02">4</div>
                                </div>
                                <div class="pa-16px pa-md-20px">
                                    <v-row class="row--xs">
                                        <v-col cols="12">
                                            <p class="txt txt--sm white--text">액세서리</p>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-col>
                </v-row>
            </div>

            <div class="mt-20px mt-md-40px">
                <v-divider class="grey darken-4 mb-20px mb-md-30px" style="border-width: 2px 0 0" />
                <v-row>
                    <v-col cols="6" md="4">
                        <v-card elevation="1">
                            <v-img :aspect-ratio="430 / 590" src="/images/sub/about/about-cover.jpg" />
                        </v-card>
                        <div class="btn-wrap btn-wrap--md">
                            <v-row justify="center">
                                <v-col cols="auto">
                                    <v-btn v-bind="{ ...btn_primary, ...$attrs }" class="min-w-120px">다운로드</v-btn>
                                </v-col>
                            </v-row>
                        </div>
                    </v-col>
                    <v-col cols="6" md="4">
                        <v-card elevation="1">
                            <v-img :aspect-ratio="430 / 590" src="/images/sub/about/about-cover2.jpg" />
                        </v-card>
                        <div class="btn-wrap btn-wrap--md">
                            <v-row justify="center">
                                <v-col cols="auto">
                                    <v-btn v-bind="{ ...btn_primary, ...$attrs }" class="min-w-120px">다운로드</v-btn>
                                </v-col>
                            </v-row>
                        </div>
                    </v-col>
                    <v-col cols="6" md="4">
                        <div class="pt-20px pt-md-0">
                            <v-card elevation="1">
                                <v-img :aspect-ratio="430 / 590" src="/images/sub/about/about-cover3.jpg" />
                            </v-card>
                            <div class="btn-wrap btn-wrap--md">
                                <v-row justify="center">
                                    <v-col cols="auto">
                                        <v-btn v-bind="{ ...btn_primary, ...$attrs }" class="min-w-120px">다운로드</v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </page-section>
    </client-page>
</template>

<script>
import { btn_primary } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import AboutCommunityArticle from "@/components/client/sub/about/about-community-article.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        AboutCommunityArticle,
    },
    data() {
        return {
            btn_primary,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {},
    },
};
</script>
<style lang="scss" scoped>
    .background-position {
        background-position-x: -140px !important;
    }
</style>